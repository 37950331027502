import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="video" style={{
      position: 'relative',
      paddingBottom: '56.25%',
  }}>
    <iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
      style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
      }}
    />
  </div>
)
export default Video